import React from 'react'

const Footer = () => {
  return (
    <div className="container">
        <footer className="py-3 my-4">
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                <li className="nav-item"><a href="https://twitter.com/RetnuhLIVE" class="nav-link px-2 text-body-secondary">About</a></li>
            </ul>
            <p className="text-center text-body-secondary">© 2023 SMD, Inc</p>
        </footer>
    </div>
  )
}

export default Footer