import React from 'react';
import Home from "./pages/Home";
import Generator from "./pages/Generator";
import NavBar from './Components/NavBar';
import Login from './pages/Login';
import Register from './pages/Register';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
    <NavBar />
      <Router basename="/">
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/gen" element={<Generator />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Register />} />
            <Route component={Home} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
