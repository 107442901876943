import React from 'react'

function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="mx-auto" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{"textAlign":"center"}}>
                <li className="nav-item active">
                    <a className="nav-link" href="/">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/gen">Generator</a>
                </li>
                <li className="nav-item">
                </li>
            </ul>
        </div>
    </nav>
  )
}

export default NavBar





