import React, { useState } from 'react';
import CustomDropdown from "../../Components/CustomDropdown"
import { mapWeaponToInteger,mapSkinToInteger,mapStickerToInteger, } from "./NameToIndexMapper"

const Index = () => {
    const Weapons2 = [
        "DesertEagle",
        "DualBerettas",
        "FiveSeven",
        "Glock18",
        "AK47",
        "AUG",
        "AWP",
        "FAMAS",
        "G3SG1",
        "GALILAR",
        "M249",
        'm4a4',
        'mac-10',
        'p90',
        'repulsordevice',
        'mp5-sd',
        'ump',
        'xm1014',
        'pp-bizon',
        'mag-7',
        'negev',
        'sawed-off',
        'tec-9',
        'zeusx27',
        'mp7',
        'mp9',
        'nova',
        'p250',
        'ballisticshield',
        'scar-20',
        'sg553',
        'ssg08',
        'goldenknife',
        'ctknife',
        'flashbang',
        'hegrenade',
        'smokegrenade',
        'molotov',
        'decoygrenade',
        'incendiarygrenade',
        'c4explosive',
        'medi-shot',
        'tknife',
        'm4a1-S',
        'usp-s',
        'cz75-auto',
        'r8revolver',
        'tacticalawarenessgrenade',
        'barehands',
        'breachcharge',
        'tablet',
        'weapon_melee',
        'axe',
        'hammer',
        'wrench',
        'firebomb',
        'diversiondevice',
        'fraggrenade',
        'snowball',
        'bumpmine',
        'bayonet',
        'classicknife',
        'flipknife',
        'gutknife',
        'karambit',
        'm9bayonet',
        'huntsman',
        'falchion',
        'bowie',
        'shadowdaggers',
        'paracord',
        'survival',
        'ursus',
        'navaja',
        'nomad',
        'stiletto',
        'talon',
        'skeleton',
        'brokenfanggloves',
        'bloodhoundgloves',
        'tgloves',
        'ctgloves',
        'sportgloves',
        'drivergloves',
        'handwraps',
        'motogloves',
        'specialistgloves',
        'hydragloves',
    ]
    const Skins = [
        "Default",
        "Vanilla",
        "DragonLore"
    ]
    const Stickers = [
        "foil",
        "Holo",
        "glitter"
    ]
    const Pattern = [
        "1",
        "2",
        "3"
    ]
    // weapons details
    const [selectedWeapon, setSelectedWeapon] = useState('0');
    const [selectedSkin, setSelectedSkin] = useState('0');
    const [selectedPattern, setSelectedPattern] = useState('0');
    const [selectedFloat, setSelectedFloat] = useState('0');
    // sticker details
    const [selectedSticker1, setSelectedSticker1] = useState('0');
    const [selectedSticker2, setSelectedSticker2] = useState('0');
    const [selectedSticker3, setSelectedSticker3] = useState('0');
    const [selectedSticker4, setSelectedSticker4] = useState('0');
    const [selectedStickerWare1, setSelectedStickerWare1] = useState(0.0);
    const [selectedStickerWare2, setSelectedStickerWare2] = useState(0.0);
    const [selectedStickerWare3, setSelectedStickerWare3] = useState(0.0);
    const [selectedStickerWare4, setSelectedStickerWare4] = useState(0.0);

    // handle weapon details
    const handleWeaponDropdown = (value) => {
        setSelectedWeapon(value);
    };

    const handleSkinsDropdown = (value) => {
        setSelectedSkin(value);
    };

    const handlePatternDropdown = (value) => {
        setSelectedPattern(value);
    };

    const handleFloatDropdown = (value) => {
        setSelectedFloat(value);
    };

    // handle sticker details
    const handleSticker1Dropdown = (value) => {
        setSelectedSticker1(value);
    };

    const handleSticker2Dropdown = (value) => {
        setSelectedSticker2(value);
    };

    const handleSticker3Dropdown = (value) => {
        setSelectedSticker3(value);
    };

    const handleSticker4Dropdown = (value) => {
        setSelectedSticker4(value);
    };

    const handleSticker1Ware = (value) => {
        setSelectedStickerWare1(value);
    };

    const handleSticker2Ware = (value) => {
        setSelectedStickerWare2(value);
    };

    const handleSticker3Ware = (value) => {
        setSelectedStickerWare3(value);
    };

    const handleSticker4Ware = (value) => {
        setSelectedStickerWare4(value);
    };
    

    return (
        <>
            <br />
            <div className="d-flex justify-content-center">
                <h1>Skin Generator</h1>
            </div>
            
            <br />
            <br />
            <div className="d-flex justify-content-left" style={{"display":"inline-block","margin":"10px","zIndex":"1"}}>
                <CustomDropdown options={Weapons2} onSelect={handleWeaponDropdown}/>
                <CustomDropdown options={Skins} onSelect={handleSkinsDropdown}/>
                <CustomDropdown options={Pattern} onSelect={handlePatternDropdown}/>
                <div className="form-group">
                    <label htmlFor="customRange1" className="form-label"></label>
                    <input type="range" className="form-range" id="customRange1" onChange={handleFloatDropdown} value={selectedFloat}></input>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="d-flex justify-content-center" style={{"display":"inline-block","margin":"10px","zIndex":"1"}}>
                <CustomDropdown options={Stickers} onSelect={handleSticker1Dropdown}/>
                <CustomDropdown options={Stickers} onSelect={handleSticker2Dropdown}/>
                <CustomDropdown options={Stickers} onSelect={handleSticker3Dropdown}/>
                <CustomDropdown options={Stickers} onSelect={handleSticker4Dropdown}/>
            </div>
            <div className="d-flex justify-content-center" style={{"display":"inline-block","margin":"10px","zIndex":"1"}}>
                <div className="form-group">
                    <label htmlFor="customRange1" className="form-label"></label>
                    <input type="range" className="form-range" id="customRange1" onChange={handleSticker1Ware} defaultValue={selectedStickerWare1}></input>
                </div>
                <div className="form-group">
                    <label htmlFor="customRange1" className="form-label"></label>
                    <input type="range" className="form-range" id="customRange1" onChange={handleSticker2Ware} defaultValue={selectedStickerWare2}></input>
                </div>
                <div className="form-group">
                    <label htmlFor="customRange1" className="form-label"></label>
                    <input type="range" className="form-range" id="customRange1" onChange={handleSticker3Ware} defaultValue={selectedStickerWare3}></input>
                </div>
                <div className="form-group">
                    <label htmlFor="customRange1" className="form-label"></label>
                    <input type="range" className="form-range" id="customRange1" onChange={handleSticker4Ware} defaultValue={selectedStickerWare4}></input>
                </div>
            </div>



            
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="d-flex justify-content-center">
                <div className="form-group">
                    <input type="input" 
                           className="form-control"
                           id="result" 
                           aria-describedby="result" 
                           style={{"width":"200px"}} 
                           value={`!gen ${mapWeaponToInteger(selectedWeapon)} ${mapSkinToInteger(selectedSkin)} ${mapWeaponToInteger(selectedPattern)} ${mapWeaponToInteger(selectedFloat)} ${mapStickerToInteger(selectedSticker1)} ${selectedStickerWare1} ${mapStickerToInteger(selectedSticker2)} ${selectedStickerWare2} ${mapStickerToInteger(selectedSticker3)} ${selectedStickerWare3} ${mapStickerToInteger(selectedSticker4)} ${selectedStickerWare4}`}
                        ></input>
                </div>
            </div>

        </>
    )
}

export default Index