import React, { useState } from 'react';
import './CustomDropdown.css'; // Import your CSS file

function CustomDropdown({ options, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  //eslint-disable-next-line 
  const [selectedOption, setSelectedOption] = useState(options[0] || ''); // Set the first option as default

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    setIsOpen(true);
  };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSearchTerm(option);
    setIsOpen(false);
    onSelect(option); // Call the onSelect function with the selected option
  };

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="custom-dropdown">
      <input
        className="selected-option"
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        onClick={handleDropdownClick}
        placeholder="Select an option"
      />
      {isOpen && (
        <div className="options-container">
          {filteredOptions.map((option) => (
            <div
              key={option}
              className="option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;
