import React from 'react'
import Footer from '../../Components/Footer'

const index = () => {
  return (
    <>
        <br />
        <div className="d-flex justify-content-center">
            <h1>Skins Compare</h1>
        </div>

        <br />
        <div className="d-flex justify-content-center">
            <p>This is a one stop shop website for all of your Counter Strike needs! Some things this project will do are:</p>
        </div>
        <Footer />
    </>
  )
}

export default index