const weaponToIntegerMap = {
    'deserteagle':1,
    'dualbarrettas':2,
    'fiveseven':3,
    'glock18': 4,
    'ak47':7,
    'aug':8,
    'awp':9,
    'famas':10,
    'g3sg1':11,
    'galilar':13,
    'm249':14,
    'm4a4':16,
    'mac-10':17,
    'p90':19,
    'repulsordevice':20,
    'mp5-sd':23,
    'ump':24,
    'xm1014':25,
    'pp-bizon':26,
    'mag-7': 27,
    'negev': 28,
    'sawed-off':29,
    'tec-9':30,
    'zeusx27':31,
    'mp7':33,
    'mp9':34,
    'nova':35,
    'p250':36,
    'ballisticshield':37,
    'scar-20':38,
    'sg553':39,
    'ssg08':40,
    'goldenknife':41,
    'ctknife':42,
    'flashbang':43,
    'hegrenade':44,
    'smokegrenade':45,
    'molotov':46,
    'decoygrenade':47,
    'incendiarygrenade':48,
    'c4explosive':49,
    'medi-shot':57,
    'tknife':59,
    'm4a1-S':60,
    'usp-s':61,
    'cz75-auto':63,
    'r8revolver':64,
    'tacticalawarenessgrenade':68,
    'barehands':69,
    'breachcharge':70,
    'tablet':72,
    'weapon_melee':74,
    'axe':75,
    'hammer':76,
    'wrench':78,
    'firebomb':81,
    'diversiondevice':82,
    'fraggrenade':83,
    'snowball':84,
    'bumpmine':85,
    'bayonet':500,
    'classicknife':503,
    'flipknife':505,
    'gutknife':506,
    'karambit':507,
    'm9bayonet':508,
    'huntsman':509,
    'falchion':512,
    'bowie':514,
    'shadowdaggers':516,
    'paracord':517,
    'survival':518,
    'ursus':519,
    'navaja':520,
    'nomad':521,
    'stiletto':522,
    'talon':523,
    'skeleton':525,
    'brokenfanggloves':4725,
    'bloodhoundgloves':5027,
    'tgloves':5028,
    'ctgloves':5029,
    'sportgloves':5030,
    'drivergloves':5031,
    'handwraps':5032,
    'motogloves':5033,
    'specialistgloves':5034,
    'hydragloves':5035,
};

const skinToIntegerMap = {
    'default': 0,
    'vanilla': 2,
    'dragonLore': 3,
};

const stickerToIntegerMap = {
    'foil': 1,
    'holo': 2,
    'glitter': 3,
};
  
function mapWeaponToInteger(inputString) {
    const mappedValue = weaponToIntegerMap[inputString.trim().toLowerCase()];
    return mappedValue !== undefined ? mappedValue : 0; // Return 0 for undefined mappings
}

function mapSkinToInteger(inputString) {
    const mappedValue = skinToIntegerMap[inputString.trim().toLowerCase()];
    return mappedValue !== undefined ? mappedValue : 0; // Return 0 for undefined mappings
}

function mapStickerToInteger(inputString) {
    const mappedValue = stickerToIntegerMap[inputString.trim().toLowerCase()];
    return mappedValue !== undefined ? mappedValue : 0; // Return 0 for undefined mappings
}
  
module.exports = {mapWeaponToInteger,mapSkinToInteger,mapStickerToInteger};
  